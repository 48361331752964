import React, { useState, useEffect } from 'react';
import { Box, Spinner, Flex, IconButton } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Project } from '../types/projectTypes';
import HeroSection from '../components/layout/HeroSection';
import VideoSection from '../components/sections/VideoSection';
import TabsSection from '../components/sections/TabsSection';
import ProjectSection from '../components/sections/ProjectSection';
import ProjectSubmissionSection from '../components/sections/ProjectsForm';
//import EventsSection from '../components/sections/EventsSection';
//import CharitySection from '../components/sections/CharitySection';
import AcademySection from '../components/sections/AcademySection';
import JoinUsSection from '../components/sections/JoinUsSection';
import TeamSection from '../components/sections/TeamSection';
import SponsorsSection from '../components/sections/SponsorsSection';
import FAQSection from '../components/sections/FAQSection';
import ContactSection from '../components/sections/ContactSection';
import TalentosSection from '../components/sections/TalentosSection'; // Importa la nueva sección
import { FaWhatsapp } from 'react-icons/fa';

interface HomePageProps {
  projectsConfig: {
    //activeProjects: Project[];
    upcomingProjects: Project[];
    studyProjects: Project[];
  };
}

const HomePage: React.FC<HomePageProps> = ({ projectsConfig }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Simula la carga de la página con un retraso de 1 segundo
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Scroll to the "Servicios" section if the path is "/servicios"
    if (location.pathname === '/servicios') {
      const serviciosSection = document.getElementById('servicios');
      if (serviciosSection) {
        serviciosSection.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return () => clearTimeout(timer); // Limpia el temporizador al desmontar el componente
  }, [location]);

  if (loading) {
    return (
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        bg="white"
      >
        <Spinner size="xl" color="blue.500" />
        <Box mt={4} fontSize="lg" color="gray.500">
          Cargando...
        </Box>
      </Flex>
    );
  }

  return (
    <>
      <section id="hero" style={{ paddingTop: '60px' }}>
        <HeroSection />
      </section>
      <section id="video" style={{ paddingTop: '0px' }}>
        <VideoSection />
      </section>
      <section id="servicios" style={{ paddingTop: '0px' }}>
        <TabsSection />
      </section>
      <section id="projectform" style={{ paddingTop: '0px' }}>
        <ProjectSubmissionSection />
      </section>
      <section id="projects" style={{ paddingTop: '0px' }}>
        <ProjectSection
          //activeProjects={projectsConfig.activeProjects.slice(0, 3)}
          upcomingProjects={projectsConfig.upcomingProjects.slice(0, 3)}
          studyProjects={projectsConfig.studyProjects.slice(0, 3)}
        />
      </section>
      

      {/* Nueva sección de Talentos */}
      <section id="talentos" style={{ paddingTop: '0px' }}>
        <TalentosSection />
      </section>

      {/* <section id="events" style={{ paddingTop: '60px' }}>
        <EventsSection />
      </section> */}
     {/* <section id="charity" style={{ paddingTop: '60px' }}>
        <CharitySection />
  </section>*/}
      <section id="academy" style={{ paddingTop: '60px' }}>
        <AcademySection />
      </section>
      <section id="join-us" style={{ paddingTop: '60px' }}>
        <JoinUsSection />
      </section>
      <section id="team" style={{ paddingTop: '60px' }}>
        <TeamSection />
      </section>
      <section id="sponsors" style={{ paddingTop: '60px' }}>
        <SponsorsSection />
      </section>
      <section id="faq" style={{ paddingTop: '60px' }}>
        <FAQSection />
      </section>
      <section id="contact" style={{ paddingTop: '60px' }}>
        <ContactSection />
      </section>
      
      {/* Botón de WhatsApp */}
      <Box position="fixed" bottom="2%" right="2%" zIndex="tooltip">
        <IconButton
          as="a"
          href="https://wa.me/34722568817"
          aria-label="Chat with us on WhatsApp"
          icon={<FaWhatsapp fontSize="30px" />}
          size="lg"
          colorScheme="teal"
          borderRadius="full"
          boxSize="60px" 
          isRound
          shadow="md"
          _hover={{ shadow: 'lg' }}
        />
      </Box>
    </>
  );
};

export default HomePage;
