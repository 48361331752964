import React, { useState } from 'react';
import { Box, Grid, Text, Heading, Button, Image, Stack, useMediaQuery } from '@chakra-ui/react';

// Importación de imágenes
import Image1 from '../../assets/images/consultoria.webp';
import Image2 from '../../assets/images/tecnologia.webp';
import Image3 from '../../assets/images/marketing.webp';
import Image4 from '../../assets/images/legal.webp';
import Image5 from '../../assets/images/caridad.jpg';
import Image6 from '../../assets/images/visibilidad.jpeg';

const TabSection: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(1); // Inicializa con un número, no null
  const [expandedTab, setExpandedTab] = useState<number | null>(null);

  const tabs = [
    
    {
      id: 1,
      title: 'Portal de acceso',
      content: `Publicamos y descubrimos proyectos blockchain y de tokenizacion de forma fácil y efectiva.

      Puedes publicar tu proyecto y darle máxima visibilidad gracias a nuestras campañas de marketing dirigidas a una audiencia interesada en este ecosistema. Además, ofrecemos un portal centralizado donde los usuarios pueden encontrar proyectos clasificados por categoría o estado, ahorrando tiempo y esfuerzo.
      
      🎯 Más visibilidad para tus ideas.
      🔍 Búsquedas rápidas y organizadas.`,
      image: Image6,
    },
    
    {
      id: 2,
      title: 'Consultoría',
      content: `Te ayudamos a comprender, planificar y ejecutar la transformación de activos tradicionales en tokens digitales para aprovechar la multitud de ventajas que ofrece. Te brindamos servicios especializados para ayudarte en aprovechar la tecnología de tokenización y la tokenización de activos. Te diseñamos las estrategias necesarias para atraer a los inversores óptimos para tus proyectos, así como crear la comunidad más amplia interesada en cualquier proceso de tokenización. Te diseñamos desde el inicio del proyecto de tokenomics o en cualquier etapa en la que te encuentres considerando todos los aspectos económicos, tecnológicos y legales así como todos aquellos que sean de tu interés y quieras plasmar en la tokenización.`,
      image: Image1,
    },
    {
      id: 3,
      title: 'Desarrollo Tecnológico',
      content: `Tras la consultoría, podrás contratar la tecnología con nosotros para poder ejecutar tu proyecto. Nuestros desarrolladores trabajan de la mano de los abogados para poder ofrecerte soluciones robustas tanto para la emisión de tokens como NFTs. Desarrollamos los smart contracts para tu proyecto y nos encargamos de que la plataforma cumpla con todos los requisitos legales como el AML/KYC y los contratos legales.`,
      image: Image2,
    },
    {
      id: 4,
      title: 'Marketing',
      content: `Nuestro departamento de marketing te ofrecerá las campañas más atractivas para conseguir la comunidad que respalden tus proyectos sean del tipo que sean. Para ello utilizaremos los canales más tradicionales, así como plataformas de financiación participativas tokenizadas como tokens de utilidad o NFTs.`,
      image: Image3,
    },
    {
      id: 5,
      title: 'Asesoría Legal',
      content: `Nuestro departamento jurídico te guiara en tus proyectos de una forma sencilla y entendible siempre acorde a la legislación vigente. Cada proyecto tendrá sus propias características y al tiempo que se prepara el proyecto se los más específicos para el sector construyendo una comunidad sólida. Celebraremos eventos distraídos, con ejemplos claros para cualquier persona que desconozca este ecosistema. Haremos participes a cualquiera que esté interesado sin abordarle con terminología que le aburra.`,
      image: Image4,
    },
    {
      id: 6,
      title: 'Apoyo Benéfico',
      content: `Desde nuestro inicio teníamos claro que si podemos ayudar lo haríamos y no me refiero solo en el trabajo. Cada proyecto podrá colaborar con entidades benéficas de una forma clara y transparente a través de nuestra web. Se podrá visualizar en tiempo real las cantidades recaudadas y para qué son utilizadas. Una comunidad responsable puede hacer mucho más de lo que imagina por muy poco. Si hay algo que nos mantiene vivos es la ilusión de hacer feliz a alguien.`,
      image: Image5,
    }
    

  ];

  const currentTab = tabs.find(tab => tab.id === selectedTab);

  // Utiliza useMediaQuery para aplicar estilos específicos para pantallas grandes
  const [isLaptopOrLarger] = useMediaQuery("(min-width: 992px)");

  return (
    <Box w="100vw" bg="black" py={10} px={4}>
      <Box maxW="1250px" mx="auto">
        <Heading as="h2" textAlign="center" mb={8} fontSize={isLaptopOrLarger ? "5xl" : "3xl"} color="white">
          ¿Qué ofrece TokenMeet?
        </Heading>
        <Box p={4} borderRadius="md">
          {isLaptopOrLarger ? (
            <Grid
              templateColumns="20% 45% 30%"
              gap={8}
              alignItems="flex-start"
            >
              {/* Columna 1: Números de pestañas */}
              <Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  {tabs.map(tab => (
                    <Button
                      key={tab.id}
                      onClick={() => setSelectedTab(tab.id)}
                      variant="unstyled"
                      fontSize="xl"
                      fontWeight={selectedTab === tab.id ? 'bold' : 'normal'}
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      _hover={{ color: 'white' }}
                      mb={2}
                      textAlign="center"
                      w="100%"
                      paddingTop="10px" // Agrega espacio en la parte superior para evitar el corte
                    >
                      <Box
                        bgGradient={selectedTab === tab.id ? 'linear(to-r, #0ef1fa, #123e5b)' : 'none'}
                        borderRadius="12px"
                        width="50px"
                        height="50px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.2)"
                      >
                        {`0${tab.id}`}
                      </Box>
                    </Button>
                  ))}
                </Box>
              </Box>

              {/* Columna 2: Contenido */}
              <Box>
                <Box
                  position="relative"
                  width="100%"
                  borderRadius="md"
                  overflow="hidden"
                  p={4}
                  bg="rgba(0, 0, 0, 0.5)" // Fondo semitransparente para mejorar la legibilidad del texto
                >
                  <Heading as="h3" fontSize="2xl" color="white" mb={4} borderBottom="2px solid white">
                    {currentTab?.title || 'Título no disponible'}
                  </Heading>
                  <Text fontSize="lg" color="white" noOfLines={expandedTab === currentTab?.id ? undefined : 4}>
                    {currentTab?.content || 'Contenido no disponible'}
                  </Text>
                  <Button
                    onClick={() => setExpandedTab(expandedTab === currentTab?.id ? null : currentTab?.id || null)}
                    variant="link"
                    color="#0ef1fa"
                    mt={4}
                    textAlign="left"
                    display="block"
                  >
                    {expandedTab === currentTab?.id ? 'Mostrar menos' : 'Mostrar más'}
                  </Button>
                </Box>
              </Box>

              {/* Columna 3: Imagen */}
              <Box>
                <Image
                  src={currentTab?.image || ''}
                  alt={currentTab?.title || 'Imagen no disponible'}
                  objectFit="cover"
                  width="100%"
                  height="auto"
                  borderRadius="md"
                />
              </Box>
            </Grid>
          ) : (
            <Stack spacing={8} align="center">
              <Stack spacing={4} w="100%">
                <Box display="flex" justifyContent="space-between" w="100%" overflowX="auto">
                  {tabs.map(tab => (
                    <Button
                      key={tab.id}
                      onClick={() => setSelectedTab(tab.id)}
                      variant="unstyled"
                      fontSize="xl"
                      fontWeight={selectedTab === tab.id ? 'bold' : 'normal'}
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      _hover={{ color: 'white' }}
                      mb={2}
                      textAlign="center"
                      w="auto"
                      paddingTop="10px" // Agrega espacio en la parte superior para evitar el corte
                    >
                      <Box
                        bgGradient={selectedTab === tab.id ? 'linear(to-r, #0ef1fa, #123e5b)' : 'none'}
                        borderRadius="12px"
                        width="50px"
                        height="50px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.2)"
                      >
                        {`0${tab.id}`}
                      </Box>
                    </Button>
                  ))}
                </Box>
              </Stack>

              <Box
                position="relative"
                width="100%"
                borderRadius="md"
                overflow="hidden"
                p={4}
                bg="rgba(0, 0, 0, 0.5)" // Fondo semitransparente para mejorar la legibilidad del texto
              >
                <Heading as="h3" fontSize="2xl" color="white" mb={4} borderBottom="2px solid white">
                  {currentTab?.title || 'Título no disponible'}
                </Heading>
                <Text fontSize="lg" color="white" noOfLines={expandedTab === currentTab?.id ? undefined : 4}>
                  {currentTab?.content || 'Contenido no disponible'}
                </Text>
                <Button
                  onClick={() => setExpandedTab(expandedTab === currentTab?.id ? null : currentTab?.id || null)}
                  variant="link"
                  color="#0ef1fa"
                  mt={4}
                  textAlign="left"
                  display="block"
                >
                  {expandedTab === currentTab?.id ? 'Mostrar menos' : 'Mostrar más'}
                </Button>
              </Box>

              <Image
                src={currentTab?.image || ''}
                alt={currentTab?.title || 'Imagen no disponible'}
                objectFit="cover"
                width="100%"
                height="auto"
                borderRadius="md"
              />
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TabSection;
