import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Input,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Image,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import GradientButton from '../buttons/GradientButton';
import Image1 from '../../assets/images/photo_2024-11-26 12.46.08.jpeg'; // Importa la imagen

const ProjectSubmissionSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Control del popup
  const [projectName, setProjectName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    if (!projectName || !email || !description) {
      toast({
        title: 'Faltan datos',
        description: 'Por favor, completa todos los campos antes de enviar.',
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post('https://tokenmeet.vercel.app/api/projects', {
        projectName,
        email,
        description,
      });

      if (response.status === 200) {
        toast({
          title: 'Proyecto enviado',
          description: 'Tu proyecto ha sido registrado exitosamente.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });

        // Limpiar el formulario después del envío
        setProjectName('');
        setEmail('');
        setDescription('');
        onClose(); // Cerrar el popup
      }
    } catch (error) {
      console.error('Error al enviar el proyecto:', error);
      toast({
        title: 'Error',
        description: 'Hubo un problema al enviar tu proyecto. Inténtalo de nuevo más tarde.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box bg="gray.100" py={10} px={5}>
      <VStack spacing={6} maxW="800px" mx="auto" textAlign="center">
        <Heading as="h2" fontSize={{ base: '3xl', md: '5xl' }} mb={3}>
          Información portal de acceso
        </Heading>
        <Image
          src={Image1}
          alt="TokenMeet: Publica y descubre proyectos"
          borderRadius="md"
          mb={6}
        />
        <Text textAlign="left" color="gray.600">
          TokenMeet: La plataforma ideal para publicar y descubrir proyectos
          blockchain y de tokenización 🌐✨
        </Text>
        <Text textAlign="left" color="gray.600">
          ¿Tienes un proyecto que merece ser conocido? <b>En TokenMeet puedes
          publicarlo y darle la visibilidad que necesita para destacar.</b> Nuestra
          plataforma está diseñada para que tus ideas lleguen directamente a
          quienes buscan oportunidades innovadoras. 🎯
        </Text>
        <Text textAlign="left" color="gray.600">
          <b>¿Eres usuario o inversor?</b> Aquí encontrarás proyectos organizados de
          forma sencilla y accesible. <b>Ya no tendrás que buscar en diferentes
          lugares sin éxito.</b> TokenMeet centraliza la información, permitiéndote
          localizar iniciativas fácilmente por categorías como arte, real estate,
          automoción, deporte, entretenimiento, ¡y mucho más! Además, podrás
          filtrar por estado: proyectos activos, en preparación o en estudio.
        </Text>
        <Text textAlign="left" color="gray.600">
          🔍 <b>Publicar y encontrar nunca fue tan fácil:</b> Si eres creador, tu
          proyecto será visible para un público relevante. Si eres usuario, podrás
          explorar un índice claro y organizado que te llevará directamente a lo
          que buscas.
        </Text>
        <Text textAlign="left" color="gray.600">
          🌟 <b>Impulsamos el conocimiento y la democratización:</b> TokenMeet no
          solo facilita el acceso, también promueve la educación y el entendimiento
          del mundo blockchain a través de diversos canales.
        </Text>
        <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} mt={8}>
          ¿Por qué TokenMeet?
        </Heading>
        <Text textAlign="left" color="gray.600">
          Con nosotros, tu proyecto no solo está en una plataforma, sino que
          también está <b>respaldado por campañas de marketing diseñadas para
          atraer al público adecuado.</b> Dedicamos tiempo, esfuerzo y recursos
          para conectar tu proyecto con una comunidad interesada en participar en
          este ecosistema, logrando así <b>una visibilidad mucho mayor.</b> 💡✨
        </Text>
        <Text textAlign="left" color="gray.600">
          <b>Publica, encuentra e invierte sin complicaciones.</b> Únete a
          TokenMeet y sé parte del futuro. 🚀
        </Text>
        <GradientButton
          gradientColor1="#0ef1fa"
          gradientColor2="#0b4d78"
          onClick={onOpen}
        >
          Solicita Condiciones
        </GradientButton>
        {/* Aviso de responsabilidad */}
      <Box mt={10} textAlign="left" color="gray.500" fontSize="sm" >
        <Text fontWeight="bold">Aviso de responsabilidad y transparencia.</Text>
        <Text>
          TokenMeet es una plataforma que facilita el acceso a proyectos relacionados con blockchain y tokenización. Sin embargo, no recomendamos ni avalamos ninguna inversión ni garantizamos el éxito de los proyectos publicados.
        </Text>
        <Text>
          Es responsabilidad de los usuarios realizar sus propias investigaciones y análisis antes de tomar cualquier decisión de participación o inversión. TokenMeet no se hace responsable de las acciones, resultados o garantías proporcionadas por los proyectos ofrecidos en la plataforma.
        </Text>
        <Text>
          Además, TokenMeet no tiene control ni influencia sobre los proyectos ni sobre las decisiones de las personas o entidades detrás de ellos. Nuestra misión es únicamente proporcionar un espacio accesible y organizado para que los usuarios descubran y publiquen iniciativas.
        </Text>
      </Box>
      </VStack>

      {/* Modal para el formulario */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Solicita Condiciones</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder="Tu nombre"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
              <Input
                placeholder="Correo Electrónico"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Textarea
                placeholder="Descripción del Proyecto"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                resize="vertical"
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <GradientButton
              gradientColor1="#0ef1fa"
              gradientColor2="#0b4d78"
              onClick={handleSubmit}
              isLoading={isSubmitting}
              loadingText="Enviando..."
            >
              Solicita Condiciones
            </GradientButton>
            <GradientButton
              gradientColor1="gray.300"
              gradientColor2="gray.500"
              variant="ghost"
              onClick={onClose}
              ml={3}
            >
              Cancelar
            </GradientButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      
    </Box>
  );
};

export default ProjectSubmissionSection;
