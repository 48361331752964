export type EducationResource = {
    id: string;
    title: string;
    type: 'video' | 'news';
    image?: string;  // Imagen para noticias
    shortDescription?: string;  // Descripción corta para noticias
    videoUrl?: string;  // URL para el video
    category: string;  // Categoría del recurso
    newsUrl?: string;  // URL para la noticia
};

function getYouTubeThumbnail(videoUrl: string): string {
    let videoId = '';
    if (videoUrl.includes('youtu.be')) {
        videoId = videoUrl.split('youtu.be/')[1];
    } else if (videoUrl.includes('v=')) {
        videoId = videoUrl.split('v=')[1];
    }

    if (videoId.includes('&')) {
        videoId = videoId.split('&')[0];
    }

    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
}

export const educationResourcesConfig: EducationResource[] = [
    {
        id: '11',
        title: 'Cómo Abrir Tu Primera Wallet Digital para la Tokenización de Activos',
        type: 'video',
        videoUrl: 'https://youtu.be/TFt1fRT44MA',
        category: 'Educativo',
        image: getYouTubeThumbnail('https://youtu.be/TFt1fRT44MA') 
    },
    {
        id: '10',
        title: 'TokenMeet en #SURWinesGourmet | Con Juanjo Escudero Marcos',
        type: 'video',
        videoUrl: 'https://youtu.be/G-fHb0tbeZU',
        category: 'Informativa',
        image: getYouTubeThumbnail('https://youtu.be/G-fHb0tbeZU') 
    },
    {
        id: '9',
        title: 'Javier López #4 | Asesor Web 3 de FNDB (Federación Nacional de Digitalización y Blockchain)',
        type: 'video',
        videoUrl: 'https://youtu.be/GCMhtbI6fVk',
        category: 'Informativa',
        image: getYouTubeThumbnail('https://youtu.be/GCMhtbI6fVk') 
    },
    {
        id: '8',
        title: 'TokenMeet en #SURWinesGourmet Parte 2 | Con Lorena y Rocío Morales',
        type: 'video',
        videoUrl: 'https://youtu.be/L7ai63jl7oo',
        category: 'Informativa',
        image: getYouTubeThumbnail('https://youtu.be/L7ai63jl7oo') 
    },
    {
        id: '7',
        title: 'Entrevista a Ramón Mestre (CEO de BlockTech Solutions)',
        type: 'video',
        videoUrl: 'https://youtu.be/YJF0hl2cPfc?si=v8Jjs4LeHiHceKz0',
        category: 'Informativa',
        image: getYouTubeThumbnail('https://youtu.be/YJF0hl2cPfc?si=v8Jjs4LeHiHceKz0') 
    },
    {
        id: '6',
        title: 'Entrevista a Alberto Valdés (CEO de Arkand-Arkibit)',
        type: 'video',
        videoUrl: 'https://youtu.be/lASybBAeK3Q',
        category: 'Informativa',
        image: getYouTubeThumbnail('https://youtu.be/lASybBAeK3Q') 
    },
    {
        id: '5',
        title: 'Entrevista a Nani Boronat',
        type: 'video',
        videoUrl: 'https://youtu.be/8hg_baW6tlw?si=cDlO0wtKL3ID20uh',
        category: 'Informativa',
        image: getYouTubeThumbnail('https://youtu.be/8hg_baW6tlw?si=cDlO0wtKL3ID20uh') 
    },
    
    {
        id: '3',
        title: 'Como participar en los proyectos de los miembros de la comunidad',
        type: 'video',
        videoUrl: 'https://youtu.be/fosp_8-Kfyc?si=GciRunwTsM5eVQf0',
        category: 'Blockchain',
        image: getYouTubeThumbnail('https://youtu.be/fosp_8-Kfyc?si=GciRunwTsM5eVQf0') 
    },
    
    {
        id: '1',
        title: 'Presentación de TokenMeet por Manuel Escudero',
        type: 'video',
        videoUrl: 'https://youtu.be/zFZ5uRuf1lQ?si=yTTtyNTxaCoSJnLk',
        category: 'Informativo',
        image: getYouTubeThumbnail('https://youtu.be/zFZ5uRuf1lQ?si=yTTtyNTxaCoSJnLk') 
    },
];
