import React, { useState } from 'react';
import { Box, Flex, Image, Link as ChakraLink, IconButton, VStack } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import logo from '../../assets/images/logo1.png';
import JoinTokenMeetButton from '../buttons/JoinTokenMeetButton';

const MotionBox = motion(Box);

const MyNavbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navLinks = [
    { title: "Ofrecemos", url: "https://token-meet.com/#servicios" },
    { title: "Info portal de acceso", url: "#projectform" },
    { title: "Portal proyectos", url: "/projects" },
    { title: "Comunidad", url: "/tokenmeet-info" },
    { title: "Quienes Somos", url: "/about-us" },
    { title: "Proyecto Talentos", url: "/talentos" },
  ];

  const menuVariants = {
    open: { opacity: 1, y: 0, display: "flex", transition: { duration: 0.5 } },
    closed: { opacity: 0, y: "-100%", transitionEnd: { display: "none" }, transition: { duration: 0.5 } },
  };

  // Manejar el clic en el logo
  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault(); // Evita la navegación por defecto

    // Si ya estamos en la página de inicio, forzamos la recarga
    if (location.pathname === '/') {
      window.location.reload();
    } else {
      // Navegamos a la página de inicio
      navigate('/');
    }
  };

  return (
    <Box as="nav" bg="white" width="100%" position="fixed" top={0} zIndex={1000} boxShadow="md">
      <Flex maxW="1800px" mx="auto" py={3} px={4} align="center" justify="space-between">
        {/* Enlace del logo */}
        <ChakraLink as={RouterLink} to="/" onClick={handleLogoClick}>
          <Image src={logo} alt="TokenMeet Logo" height="30px" />
        </ChakraLink>

        <Flex
          as="nav"
          display={{ base: "none", lg: "flex" }}
          mx="auto"
          align="center"
        >
          {navLinks.map((link, index) => (
            <ChakraLink
              key={index}
              href={link.url}
              px={3}
              py={2}
              fontSize="sm"
              color="black"
              _hover={{ textDecoration: "underline" }}
            >
              {link.title}
            </ChakraLink>
          ))}
        </Flex>

        <JoinTokenMeetButton display={{ base: "none", lg: "block" }}>
          Unirse a TokenMeet
        </JoinTokenMeetButton>

        <IconButton
          aria-label="Open menu"
          icon={isMobileMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
          display={{ base: "block", lg: "none" }}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          size="sm"
        />
      </Flex>

      <MotionBox
        position="fixed"
        top="64px"  // Ajustar para que esté justo debajo del navbar (ajusta según la altura del navbar)
        left={0}
        right={0}
        height="calc(100vh - 64px)"  // El resto de la pantalla después del navbar
        bg="white"
        zIndex={999}
        initial="closed"
        animate={isMobileMenuOpen ? "open" : "closed"}
        variants={menuVariants}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <VStack spacing={8}>
          {navLinks.map((link, index) => (
            <ChakraLink
              key={index}
              href={link.url}
              fontSize="2xl"
              color="black"
              onClick={() => setIsMobileMenuOpen(false)}
              _hover={{ textDecoration: "underline", color: "blue.500" }}
            >
              {link.title}
            </ChakraLink>
          ))}
          <JoinTokenMeetButton onClick={() => setIsMobileMenuOpen(false)}>
            Unirse a TokenMeet
          </JoinTokenMeetButton>
        </VStack>
      </MotionBox>
    </Box>
  );
};

export default MyNavbar;
